import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "tailwindcss/tailwind.css"; // Make sure to include Tailwind CSS in your project

const WelcomePage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const name = query.get("name");
  const username = query.get("username");
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const width = (canvas.width = window.innerWidth);
    const height = (canvas.height = window.innerHeight);
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("");
    const fontSize = 16;
    const columns = Math.floor(width / fontSize);
    const drops = Array(columns).fill(1);

    const draw = () => {
      ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
      ctx.fillRect(0, 0, width, height);
      ctx.fillStyle = "#0F0";
      ctx.font = `${fontSize}px monospace`;

      for (let i = 0; i < drops.length; i++) {
        const text = letters[Math.floor(Math.random() * letters.length)];
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);

        if (drops[i] * fontSize > height && Math.random() > 0.975) {
          drops[i] = 0;
        }

        drops[i]++;
      }
    };

    const interval = setInterval(draw, 33);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-900 via-black to-gray-900 text-hacker-green">
      <canvas
        ref={canvasRef}
        className="absolute inset-0 w-full h-full"
      ></canvas>
      <div className="relative z-10 p-4 sm:p-6 md:p-8 lg:p-12 bg-gray-900 bg-opacity-75 rounded-lg shadow-2xl max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl  ">
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl xl:text-4xl font-extrabold mb-6 text-center animate-pulse drop-shadow-md">
          Hi {name}
        </h1>
        <p className="mt-4 text-center text-xl md:text-2xl">
        0xUsername: <span className="font-mono font-bold  text-hacker-green">{username}</span>
        </p>
        <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 text-center drop-shadow-md">
          OnLine to OnChain
        </h1>
        <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-center drop-shadow-md">
          (Coming Soon)
        </h1>
      </div>
    </div>
  );
};

export default WelcomePage;

import React, { useEffect, useRef } from "react";
import "tailwindcss/tailwind.css"; // Make sure to include Tailwind CSS in your project

const NotRegisteredPage = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const width = (canvas.width = window.innerWidth);
    const height = (canvas.height = window.innerHeight);
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("");
    const fontSize = 16;
    const columns = Math.floor(width / fontSize);
    const drops = Array(columns).fill(1);

    const draw = () => {
      ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
      ctx.fillRect(0, 0, width, height);
      ctx.fillStyle = "#0F0";
      ctx.font = `${fontSize}px monospace`;

      for (let i = 0; i < drops.length; i++) {
        const text = letters[Math.floor(Math.random() * letters.length)];
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);

        if (drops[i] * fontSize > height && Math.random() > 0.975) {
          drops[i] = 0;
        }

        drops[i]++;
      }
    };

    const interval = setInterval(draw, 33);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-black text-hacker-green">
      <canvas
        ref={canvasRef}
        className="absolute inset-0 w-full h-full"
      ></canvas>
      <div className="relative z-10 p-6 md:p-8 lg:p-12 bg-gray-900 bg-opacity-75 rounded-lg shadow-lg  max-w-3xl">
        <h1 className="text-3xl lg:text-5xl font-bold text-center mb-4">
          You are not registered!
        </h1>
        <p className="text-lg text-center mb-8">
          Please register your profile at{" "}
          <a href="http://0xprofile.com" target="__blank" className="underline text-blue-500">
            0xprofile.com
          </a>
          .
        </p>
        <div className="flex justify-center">
          <a
            href="http://0xprofile.com"
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-lg text-xl transition duration-300"
          >
            Register Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotRegisteredPage;
